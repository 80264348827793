var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo "},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Jala Pusaka ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),(_vm.st=='0')?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1  mt-5",attrs:{"title-tag":"h2"}},[_vm._v(" Registrasi Akun 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Satminkal Balai Pelatihan dan Penyuluhan Perikanan ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Nama Kelolpok Perikanan","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Nama kelompot Perikanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Nama Kelompok"},on:{"input":function($event){return _vm.CekKelompok()}},model:{value:(_vm.registrasi.nama_kelompok),callback:function ($$v) {_vm.$set(_vm.registrasi, "nama_kelompok", $$v)},expression:"registrasi.nama_kelompok"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,239478013)})],1),(_vm.kelompok_perikanan.st =='1')?_c('div',{staticClass:"demo-spacing-0"},[_c('div',{staticClass:"alert alert-danger mt-1 alert-validation-msg",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-body"},[_c('i',{staticClass:"fa fa-warning mr-1"}),_c('span',[_vm._v("Nama kelompok "),_c('strong',[_vm._v(_vm._s(_vm.registrasi.nama_kelompok))]),_vm._v(", Sudah Terdaftar, atas nama "+_vm._s(_vm.kelompok_perikanan.nm_lengkap)+" , Email "+_vm._s(_vm.kelompok_perikanan.email)+". Silahkan registrasi dengan nama lain..")])])])]):_vm._e(),(_vm.kelompok_perikanan.st =='0')?_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"Username tidakboleh spasi dan simbol"},on:{"input":function($event){return _vm.cek_username()}},model:{value:(_vm.registrasi.username),callback:function ($$v) {_vm.$set(_vm.registrasi, "username", $$v)},expression:"registrasi.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4278653989)})],1):_vm._e(),(_vm.username_info  =='1')?_c('div',{staticClass:"demo-spacing-0"},[_c('div',{staticClass:"alert alert-danger mt-1 alert-validation-msg",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-body"},[_c('i',{staticClass:"fa fa-warning mr-1"}),_c('span',[_vm._v("Username sudah terdaftar, silahkan registrasi dengan username lain.")])])])]):_vm._e(),(_vm.kelompok_perikanan.st =='0')?_c('b-form-group',{attrs:{"label":"Nama ketua Kelompok","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","type":"text","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Nama Ketua Kelompok"},model:{value:(_vm.registrasi.nm_lengkap),callback:function ($$v) {_vm.$set(_vm.registrasi, "nm_lengkap", $$v)},expression:"registrasi.nm_lengkap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3214214604)})],1):_vm._e(),(_vm.kelompok_perikanan.st =='0')?_c('b-form-group',{attrs:{"label":"No Hp/Wa","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"noHp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","type":"number","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"085742287875"},model:{value:(_vm.registrasi.no_hp),callback:function ($$v) {_vm.$set(_vm.registrasi, "no_hp", $$v)},expression:"registrasi.no_hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2075407456)})],1):_vm._e(),(_vm.kelompok_perikanan.st =='0')?_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.registrasi.password),callback:function ($$v) {_vm.$set(_vm.registrasi, "password", $$v)},expression:"registrasi.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2450080277)})],1):_vm._e(),(_vm.kelompok_perikanan.st =='0')?_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.registrasi.password2),callback:function ($$v) {_vm.$set(_vm.registrasi, "password2", $$v)},expression:"registrasi.password2"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3790870375)})],1):_vm._e(),(_vm.registrasi.password2 == _vm.registrasi.password)?_c('div',[(_vm.kelompok_perikanan.st =='0')?_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('i',{staticClass:"fa fa-user mr-1"}),_vm._v(" Sign up ")]):_vm._e()],1):_vm._e()],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah Memiliki Akun ?")]),_c('router-link',{attrs:{"to":"/"}},[_c('span',[_vm._v(" Silahkan Login disini")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" or ")])])],1)],1):_vm._e(),(_vm.st == '1')?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[(_vm.st == '1')?_c('registrasi_info',{attrs:{"info":_vm.info}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }