<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo ">
                <vuexy-logo />

                <h2 class="brand-text text-primary ml-1">
                    Jala Pusaka
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Register V2" />
                </div>
            </b-col>
            <!-- /Left Text-->
            <!-- Register-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5"  v-if="st=='0'">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1  mt-5">
                        Registrasi Akun 🚀
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Satminkal Balai Pelatihan dan Penyuluhan Perikanan
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="registerForm">
                        <b-form class="auth-register-form mt-2">
                            <b-form-group label="Nama Kelolpok Perikanan" label-for="register-email">
                                <validation-provider #default="{ errors }" name="Nama kelompot Perikanan"
                                    rules="required">
                                    <b-form-input id="register-email" v-model="registrasi.nama_kelompok"
                                        name="register-email" @input="CekKelompok()"
                                        :state="errors.length > 0 ? false:null" placeholder="Nama Kelompok" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <div class="demo-spacing-0" v-if="kelompok_perikanan.st =='1'">
                                <div class="alert alert-danger mt-1 alert-validation-msg" role="alert">
                                    <div class="alert-body">
                                        <i class="fa fa-warning mr-1"></i>
                                        <span>Nama kelompok <strong>{{registrasi.nama_kelompok}}</strong>, Sudah
                                            Terdaftar, atas nama {{kelompok_perikanan.nm_lengkap}} ,
                                            Email {{kelompok_perikanan.email}}. Silahkan registrasi dengan nama
                                            lain..</span>
                                    </div>
                                </div>
                            </div>
                            <!-- username -->
                            
                            <b-form-group label="Username" label-for="register-username"
                                v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="Username" rules="required">
                                    <b-form-input id="register-username" v-model="registrasi.username"
                                        name="register-username" @input="cek_username()"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Username tidakboleh spasi dan simbol" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <div class="demo-spacing-0" v-if="username_info  =='1'">
                                <div class="alert alert-danger mt-1 alert-validation-msg" role="alert">
                                    <div class="alert-body">
                                        <i class="fa fa-warning mr-1"></i>
                                        <span>Username sudah terdaftar, silahkan registrasi dengan username lain.</span>
                                    </div>
                                </div>
                            </div>
                            <b-form-group label="Nama ketua Kelompok" label-for="register-email"
                                v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="Nama Lengkap" rules="required">
                                    <b-form-input id="register-email" type="text" v-model="registrasi.nm_lengkap"
                                        name="register-email" :state="errors.length > 0 ? false:null"
                                        placeholder="Nama Ketua Kelompok" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label="No Hp/Wa" label-for="register-email"
                                v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="noHp" rules="required">
                                    <b-form-input id="register-email" type="number" v-model="registrasi.no_hp"
                                        name="register-email" :state="errors.length > 0 ? false:null"
                                        placeholder="085742287875" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- email -->
                            <!-- <b-form-group label="Email" label-for="register-email" v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="Email"  >
                                    <b-form-input id="register-email" v-model="registrasi.email" name="register-email"
                                        :state="errors.length > 0 ? false:null" placeholder="john@example.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group> -->

                            <!-- password -->
                            <b-form-group label-for="register-password" label="Password"
                                v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="Password" rules="required">
                                    <b-input-group class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-password" v-model="registrasi.password"
                                            class="form-control-merge" :type="passwordFieldType"
                                            :state="errors.length > 0 ? false:null" name="register-password"
                                            placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label-for="register-password" label="Password"
                                v-if="kelompok_perikanan.st =='0'">
                                <validation-provider #default="{ errors }" name="Password" rules="required">
                                    <b-input-group class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-password" v-model="registrasi.password2"
                                            class="form-control-merge" :type="passwordFieldType"
                                            :state="errors.length > 0 ? false:null" name="register-password"
                                            placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <div v-if="registrasi.password2 == registrasi.password">
                                <b-button variant="primary" block type="submit" @click.prevent="validationForm"
                                    v-if="kelompok_perikanan.st =='0'">
                                    <i class="fa fa-user mr-1"></i> Sign up
                                </b-button>
                            </div>

                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>Sudah Memiliki Akun ?</span>
                        <router-link to="/">
                            <span>&nbsp;Silahkan Login disini</span>
                        </router-link>

                    </p>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">
                            or
                        </div>
                    </div>


                </b-col>
            </b-col>
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5"  v-if="st == '1'" >
                <registrasi_info :info  = info   v-if ="st == '1'" ></registrasi_info>
            </b-col>
            <!-- /Register-->
        </b-row>
       
    </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import registrasi_info from './RegistrasiInfo.vue';
    /* eslint-disable global-require */
    import axios from '@/config/Axios'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BButton,
        BForm,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BCardTitle,
        BCardText,
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import {
        togglePasswordVisibility
    } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            VuexyLogo,
            BRow,
            BImg,
            BCol,
            BLink,
            BButton,
            BForm,
            BCardText,
            BCardTitle,
            BFormCheckbox,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            // validations
            ValidationProvider,
            ValidationObserver,
            registrasi_info,
        },
        mixins: [togglePasswordVisibility, Base],
        data() {
            return {
                registrasi: {},
                kelompok_perikanan: {
                    st: 0
                },
                username_info: {},
                status: '',
                username: '',
                userEmail: '',
                password: '',
                sideImg: require('@/assets/images/pages/register-v2.svg'),
                // validation
                required,
                email,
                st : "0",
                info : {}
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        mounted(){
            this.st = '0';
        },
        methods: {
            async CekKelompok() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/registrasi/cek_nm_kelompok',
                        data: {
                            nama_kelompok: self.registrasi.nama_kelompok
                        },
                        headers: {

                        }
                    })
                    .then(function (response) {
                        self.kelompok_perikanan = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async cek_username() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/registrasi/cek_username',
                        data: {
                            username: self.registrasi.username
                        },
                        headers: {

                        }
                    })
                    .then(function (response) {
                        self.username_info = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async act_register() {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/registrasi/add_user',
                        data: self.registrasi,
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.info = response.data.result;
                        self.st = '1';
                        self.notification('primary', "Selamat..", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            validationForm() {
                this.$refs.registerForm.validate().then(success => {
                    if (success) {
                        this.act_register();
                        // this.$router.push({
                        //     name: 'registrasi_info'
                        // })
                    }
                })
            },
        },
    }
    /* eslint-disable global-require */
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>